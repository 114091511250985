import React from "react";
import { Helmet } from "react-helmet";
import Phone from "../../components/Shared/Phone"
const classes = require('./WineDinner.module.scss');

const wineDinner = () => {
  return (
    <div className={classes.WineDinner}>
      <Helmet>
        <title>Waterfront Wine Dinners at Mile Marker 158 Dockside</title>
        <meta name="description" content="Check out the next wine pairing at Mile Marker 158 Dockside, located at The Wharf in Orange Beach, Alabama." />
      </Helmet>

      <div className={classes.medDef}>
        <div className={classes.menuArea}>
          <div className={classes.inside}>
            <div className={classes.titleRow}>
              <div className={classes.inner}>
                <h1>Oktoberfest Wine Dinner</h1>
                <h2>
                  October 2 <br className={classes.br1}/>
                  <span className={classes.sp1}>&nbsp;|&nbsp;</span> 6pm <span className={classes.sp2}>&nbsp;|&nbsp;</span> $60 Per Guest
                </h2>
                <h3>Hosted by Grassroots</h3>
                <h4>For Reservations: <Phone where="wineDinner" /></h4>
              </div>
            </div>
          </div>

          <div className={[classes.inside, classes.stip].join(' ')}>
            <div className={classes.menuRow}>
              <div className={classes.menuBoxFull}>
                {/* &middot; */}
                <h5>First Course</h5>
                <h4>SMOKED TROUT SALAD</h4>
                <p>Fennel / Radish / Cucumber / Carrots / Dill Yogurt Vinaigrette</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Pullus Blaufrankisch</h4>
              </div>
              
              <div className={classes.menuBoxFull}>
                <h5>Second Course </h5>
                <h4>Slow Braised Beef Rouladen</h4>
                <p>Beef Tenderloin / Pickle / Mustard / Red Cabbage / Apples / Roasted Potatoes</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Pullus Blaufrankisch</h4>
              </div>

              <div className={classes.menuBoxFull}>
                <h5>Third Course</h5>
                <h4>Wienerschnitzel</h4>
                <p>Pan Fried Pork Loin / Warm Potato Salad / Green Beans / Bacon / Onion</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Gonc Cuvee Rose</h4>
              </div>

              <div className={classes.menuBoxFull}>
                <h5>Fourth Course</h5>
                <h4>German Chocolate Cake</h4>
                <p>Chocolate / Toasted Coconut / Pecans</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Windisch Dornfelder Heimersheimer Rotenfels</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default wineDinner;